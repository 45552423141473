import { get, post } from "../Http";

export default {

  /**
   * @method 获取登录图像验证码
   * @param {*} key 随机数
   */
  getCodeImage: params =>
    get(`/admin/v1/login/captcha?key=${params}`, "", false),

  /**
   * @method 获取图形验证码
   * @param {*} key 随机数
   */
  checkLoginCode: params =>
    get(`/admin/v1/login/check`, params, false),

  /**
   * @method 登录验证
   * @param {*} key 随机数
   * @param {*} name 登录名
   * @param {*} password 密码
   * @param {*} code 验证码
   */
  login: params =>
    post(`/admin/v1/login/login`, params, false),

  /**
   * @method 退出登录
   * @param {*}
   */
  logout: params =>
    post(`/admin/v1/login/logout`, params, false),
}
